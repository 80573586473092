<template>
    <div>
        <div class="btn">
            <el-button type="primary" class="el-icon-plus" @click="addClick"> 新增</el-button>
            <el-button type="danger" style="margin-left:5px"  class="el-icon-remove-outline" @click="delClick"> 删除</el-button>
            <el-button type="primary" style="margin-left:5px"  class="el-icon-bottom" @click="Send"> 下发</el-button>
            <el-input placeholder="请输入指定的车牌" v-model="searchNo" style="width: 200px;margin-left: 10px;">
                <el-button slot="append" icon="el-icon-search" @click='search'></el-button>
            </el-input>
        </div>

        <el-table :data='$store.state.ErrProneDataList' ref='bkTable' border style="width: 100%;height: calc(100vh - 150px);max-height: calc(100vh - 150px);overflow-y: scroll;margin-top: 15px;"  stripe :highlight-current-row='true'>
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column align="center" prop="fp_source_plateno" label="错误车牌"></el-table-column>
            <el-table-column align="center" prop="fp_fuzzy_plateno" label="指定车牌"></el-table-column>
        </el-table>
  


        <el-dialog title="指定易错车牌" :visible.sync="dialogVisible" width='650px' :append-to-body='true'>
            <el-form :model="fuzzyForm" :rules="rules" ref="fuzzyForm" label-width="100px" size='medium'>
                <div class="btn form" style="width: 100%;">
                    <div class="w">
                        <el-form-item label="错误车牌">
                          <el-input v-model="errno"></el-input>
                        </el-form-item>
                    </div>
                    <div class="d w" >
                        <el-button type="primary" class="el-icon-plus" style="width: 45px;" @click="add"></el-button>
                        <el-button type="primary" class="el-icon-minus" style="width: 45px;margin-left: 0px;margin-top: 5px;" @click="remove" ></el-button>
                    </div>
                    <div class="w">
                        <ul class="box-plateno">
                            <li v-for="item in fuzzyForm.list" :key='item.plateno' :class="item.ischecked?'clickLi':'unClickLi'" @click="noClick(item)">{{item.plateno}}</li>
                        </ul>
                    </div>
                </div>
                <el-form-item label="指定为" prop="sourceno">
                    <el-input v-model="fuzzyForm.sourceno" style="width: 25%;"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="primary" @click="Save">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import util from '../../util/util'

export default{
 data(){
     return{
        isFinish: true,
        screenWidth:0,
        searchNo:'',

        dialogVisible:false,


        errno:'',
        checkedItem:null,

        fuzzyForm:{
            sourceno:'',
            list:[]
        },

        rules:{
            sourceno: [{ required: true, message: '请输入识别的错误车牌指定车牌', trigger: 'blur' }],
        }
     }
 },
 created(){
    this.screenWidth = document.body.clientWidth
    if(this.$store.state.ErrProneDataList.length===0){
        this.$store.dispatch('getErrProneDataList','')
    }
 },
 methods:{
    addClick(){
        if(util.CheckUserAuth('2-4-1')){
            this.errno=''
            this.fuzzyForm.sourceno=''
            this.fuzzyForm.list=[]
            this.dialogVisible=true
        }
    },
    Save(){
        this.$refs.fuzzyForm.validate((valid) => {
        if (valid) {
            if(this.fuzzyForm.list.length===0&&this.errno===''){
                this.$message.error('请输入指定的车牌')
                return
            }

            var nos=[]
            if(this.errno!=='')
                nos.push(this.errno)
            if(this.fuzzyForm.list.length>0){
                this.fuzzyForm.list.forEach(item=>{
                    nos.push(item.plateno)
                })
            }

            util.Post('errprone/edit',{
                sourceno:this.fuzzyForm.sourceno,
                list:nos
            }).then(res=>{
                if (res.rpStatus === 10000) {
                    this.searchNo=''
                    this.$store.dispatch('getErrProneDataList',this.searchNo)
                    this.dialogVisible = false
                } else { this.$message.error(res.rpMsg) }
            })
        }
      })
    },
    search(){
        this.$store.dispatch('getErrProneDataList',this.searchNo)
    },
    delClick(){
        if(util.CheckUserAuth('2-4-2')){
            var list = this.$refs.bkTable.selection
            if (list.length > 0) {
            this.$confirm('确定删除勾选的易错车牌？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                util.Post('errprone/del', list).then(res => {
                if (res.rpStatus === 10000) {
                    this.$store.dispatch('getErrProneDataList',this.searchNo) 
                }
                })
            }).catch(e => e)
            }
        }
    },

    add(){
        if(this.errno===''){
            this.$message.error('请输入指定的车牌')
            return
        }

        this.fuzzyForm.list.push({
            plateno:this.errno,
            ischecked:false
        })
        this.errno=''
    },
    remove(){
        if(this.checkedItem){
            this.fuzzyForm.list.splice(this.fuzzyForm.list.indexOf(this.checkedItem), 1)
        }
    },
    noClick(item){
        this.fuzzyForm.list.forEach(a => {
            a.ischecked = false
            if (a.plateno === item.plateno) {
                a.ischecked = true
                this.checkedItem=item
            }
        })
    },
    Send () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('fuzzy').then(res=>{
          this.isFinish=true
        })
      }
    }
 }
}

</script>

<style scoped>
.btn{
    display: flex;
    flex-direction: row;
}
.form{
    justify-content:space-around;
    align-items: center;
}
.w{
    width: 33%;
}
.d{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-plateno{
  border: 1px solid;
    border-radius: 6px;
    height: 70px;
    margin: 0;
    display: block;
    overflow:scroll;
    overflow-x: hidden
}
ul,li{
list-style:none;
  margin:5px;
 padding:0px;
}
li{
 cursor: pointer;
}
.clickLi{
  background-color: skyblue;
}
.unClickLi{
  background-color: white;
}

</style>